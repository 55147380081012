<template>
  <div style="width:100%;">
    <v-text-field v-model="searchTerm" solo dense hide-details class="pa-0" prepend-inner-icon="mdi-qrcode-scan"
      @click:prepend-inner="scanning" autocomplete="off" :placeholder="$t(scannerPayload.placeholder)"
      @click="selectContent" :id="scannerPayload.attrId" :ref="scannerPayload.attrRef" :disabled="disabled !== undefined ? disabled : false"
      :loading="loading !== undefined ? loading : false" :append-icon="searchTerm ? 'mdi-magnify' : ''"
      @click:append="scannerPayload.change" @input="debouncedSearchBarcode"><!--  @change="scannerPayload.change" @keypress.enter="scannerPayload.change" @keydown.tab="scannerPayload.change" -->
    </v-text-field>
    <!-- SCAN BAR CODE -->
    <v-dialog v-model="scanDialog" persistent max-width="500px" class="mt-0">
      <v-card>
        <v-card-title>
          {{ $t(scannerPayload.placeholder) }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-1">
          {{ scanError }}
            <div>
              <video id="video" style="border: 1px solid gray; width:100%; height:auto;" ref="video"></video>
            </div>
            <div id="sourceSelectPanel" v-if="videoInputDevices.length > 1">
              <v-select id="sourceSelect" v-model="selectedDeviceId" outlined :items="videoInputDevices" hide-details item-text="label" item-value="deviceId" :label="$t('message.common.changeVideoSource')"></v-select>
            </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" id="scanClose" @click="stopScanningSession">{{ $t('message.common.close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { BrowserMultiFormatReader } from '@zxing/library'
export default {
  props: ['scannerPayload', 'search', 'loading', 'disabled'],
  model: {
    prop: 'search',
    event: 'custom'
  },
  data () {
    return {
      searchTerm: this.search,
      scanDialog: false,
      scanError: '',
      codeReader: {},
      videoInputDevices: [],
      selectedDeviceId: window.localStorage.getItem('AxentivPwa_storeCamera') ? window.localStorage.getItem('AxentivPwa_storeCamera') : 0,
      debouncedSearchBarcode: null
    }
  },
  mounted () {
    requestAnimationFrame(() => {
      if (this.$refs[this.scannerPayload.attrRef]) {
        this.$refs[this.scannerPayload.attrRef].focus()
      }
    })
    this.debouncedSearchBarcode = this.$formatter.debounce(this.scannerPayload.change, 2500)
  },
  methods: {
    scanning (module) {
      const self = this
      this.scanDialog = true
      self.scanError = ''
      self.scanDialog = true
      // const selected = window.localStorage.getItem('AxentivPwa_storeCamera')
      self.codeReader = new BrowserMultiFormatReader()
      self.codeReader.getVideoInputDevices().then((videoInputDevices) => {
        self.videoInputDevices = videoInputDevices
        /* if (selected && selected !== 'undefined' && selected !== null) {
          self.selectedDeviceId = selected
        } else { */
        self.selectedDeviceId = this.$vuetify.breakpoint.mobile && videoInputDevices.length > 1 ? videoInputDevices[1].deviceId : videoInputDevices[0].deviceId
        // }
        this.scanFromVideoSource(self.selectedDeviceId, module)
      }).catch((err) => {
        console.log(err)
      })
    },
    scanFromVideoSource (videoDeviceId, module) {
      if (videoDeviceId) window.localStorage.setItem('AxentivPwa_storeCamera', videoDeviceId)
      this.codeReader.decodeOnceFromVideoDevice(videoDeviceId, 'video')
        .then((result) => {
          // this.searchTerm = result.text
          if (result.text.includes('http') || result.text.includes('i=') || result.text.includes('m=') || result.text.includes('o=') || result.text.includes('t=')) {
            const newUrl = this.getPathFromUrl(result.text)
            const lastItem = newUrl.substring(newUrl.lastIndexOf('/') + 1)
            this.searchTerm = lastItem
          } else {
            this.searchTerm = result.text
          }
          this.stopScanningSession()
          setTimeout(() => {
            this.scannerPayload.change()
          }, 50)
        }).catch((err) => {
          if (err && err.name && err.name === 'NotAllowedError') alert(this.$t('message.common.permissionDenied'))
        })
    },
    stopScanningSession () {
      this.scanDialog = false
      this.codeReader.reset()
      this.codeReader.stopContinuousDecode()
      this.codeReader = {}
    },
    selectContent () {
      if (this.$refs[this.scannerPayload.attrRef]) {
        setTimeout(() => {
          document.getElementById(this.scannerPayload.attrId).select()
        }, 50)
      }
    },
    getPathFromUrl (url) {
      return url.split('?')[0]
    }
  },
  watch: {
    selectedDeviceId (val) {
      if (val) {
        window.localStorage.setItem('AxentivPwa_storeCamera', val)
        this.scanFromVideoSource(val, this.module)
      }
    },
    search (val) {
      this.searchTerm = val
    },
    searchTerm () {
      this.$emit('custom', this.searchTerm)
    }
  }
}
</script>
